
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* .poppins ,span , li{
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
} */
.blog-color-primary{
    /* color: #02598A; */
    color: #000018;
}
/* .blog-main-heading{
font-size:42px;
font-weight: bold;
line-height:1.2;
} */
.blog-main-heading {
font-size: 30px;
font-weight: 500;
line-height: 1.2;
}


.contributor-card-img{
width: 45px;
height: 45px;
border-radius: 50%;
}
.contributor-card-value{
  font-weight: bold;
  font-size: 11px;
}
.contributor-card-label{
  font-size: 11px;
}
.breadcrumb{
margin-left: -10px;
}
ol li {
color: rgb(100, 99, 99);
}

ol li a{
cursor: pointer;
color: rgb(100, 99, 99);
}
ol li:hover {
color: #0e94ac;
}
ol li a:hover {
color: #0e94ac;
}
/* .para{
  color:black;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
} */

@media (max-width: 768px) {
.blog-main-heading {
  font-size: 36px; /* Adjust as needed for smaller screens */
}
}

@media (max-width: 576px) {
.blog-main-heading {
  font-size: 20px; /* Adjust as needed for even smaller screens */
}
}

/* Blog section 2 */
.blog_section_2 img{
  width: 100%;
  max-height: 240px;
  border-radius: 20px;
}

/* blog section 4 */
/* .section4_child1{

} */

/* table */
.table {
  border-collapse: collapse; /* Collapse borders */
  border-color: #0e94ac; /* Set border color */
}
.table th,
.table td {
  border-bottom: 1px solid #0e94ac; /* Set bottom border */
}
.table th {
  border-top: none; /* Remove top border from table header */
}
.table thead tr th {
  border-bottom: 1px solid #0e94ac; /* Set bottom border color for table header */
}

.table tbody td span {
  font-weight: bold;
}
