.text-color-for-all-page  h1,.text-color-for-all-page h2,.text-color-for-all-page h3 ,.text-color-for-all-page h4,.text-color-for-all-page h5,

.text-color-for-all-page h6,.text-color-for-all-page p,.text-color-for-all-page span{
     color:#fff;
}
.font-family-section{
     font-family: -apple-system,BlinkMacSystemFont,  "Segoe UI",
     Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.teatments-markup h3 a{
     color: #4a4a4a;
     font-size: 21px;
     line-height: 20px;
}
.teatments-markup p{
     color:#4a4a4a;
     font-weight: 100;
}
/* , .teatments-markup a */
.banner-mobile-advert-out-mibleshow{
     background: #efefef;
     padding: 15px;
}
.banner-mobile-advert-mibleshow {
     background: #fff;
     border-radius: 7px;
     border: 1px solid #684286;
     clear: both;
     margin: 0;
     display: flex;
     flex-direction: row;
 }
 .banner-mobile-advert-mibleshow {
     width: 100%;
     display: flex;
     padding: 15px;
 }
  .inner-mibleshow {
     width: 100%;
     display: flex;
     padding: 15px;
 }
 .banner-mobile-advert-mibleshow .inner-mibleshow  .pig-area-mibleshow {
     display: flex;
     max-width: 400px;
     margin: auto;
     align-items: center;
 }
 .pig-area-mibleshow img {
     max-width: 100px;
     /* width: auto; */
 }
 .big-mibleshow{
    margin-left: 7%;
    align-items: center;
 }
@media screen and (min-width:500px) {
     .banner-mobile-advert-out-mibleshow{
          display: none;
       
     }
  
}

@media (max-width: 991.98px){
     .page-home .main-section 
     .section-bg-overlay .container .row.home-panel h1.h2, 
     .page-home .main-section .section-bg-overlay .container .row.home-panel h2.h2.h1,
      .page-home .main-section .section-bg-overlay .container .row.home-panel h3.h2.h1, 
      .page-home .main-section .section-bg-overlay .container .row.home-panel h4.h2.h1, 
      .page-home .main-section .section-bg-overlay .container .row.home-panel h5.h2.h1, 
      .page-home .main-section .section-bg-overlay .container .row.home-panel h6.h2.h1{
          font-size: 20px;
    line-height: 26px;
      }
}
.card-body-teatments{
     padding: 1.5rem;
}

.total-price-teatments{
     font-size: 14px;
     line-height: 133%;
     padding-top: 10px;
     color: #535353;
     position: absolute;
     top: -70px;
     left: -25px;
     display: block;
     width: 102px;
     height: 102px;
     border-radius: 51px;
     border: 4px solid #d4a54e;
     text-align: center;
     background: #fff;
     transition: all .3s
}
@media screen and (max-width:767px) {

     .card-body-single-teatments  {
          top: auto;
          bottom: 10px;
          left: 10px;
          right: auto;
      }
     .button-show-and-hide{
          display: none;
     }
    
     .desc-treatment{
     
          font-size: 14px;
          line-height: 19px;
          text-align: left;
          width: 50%;
          margin-bottom: 0;
      
}

     .total-price-teatments{
          right: 12px;
    left: auto;
    top: 12px;
          /* font-size: 14px;
    line-height: 133%;
    padding-top: 10px;
    color: #535353;
    position: absolute;
    top: -70px;
    left: -25px;
    display: block;
    width: 102px;
    height: 102px;
    border-radius: 51px;
    border: 4px solid #d4a54e;
    text-align: center;
    background: #fff;
    transition: all .3s ease-in-out; */
     }
}
@media only screen and (max-width: 480px){
     .banner-mobile-advert-out-mibleshow{
          padding: 0;
     }
     .pig-area-mibleshow img {
          max-width: 80px;
          margin-left: -20px;
          /* width: auto; */
      }
    .home-desk-div{
        width: 100%!important;
        display: flex !important;
        align-items: center;
        padding: 2px;
        flex-direction: column!important;
    }
    /* .offer-circle .desktop{
        display: none!important;
    } */
   /* .dental-imp{
    width: auto;
    display: inline;
    justify-content: center;
   }
   .price-from-circle{
        display: none!important;
   }
   .dental1{
    padding: 0!important;
    width: 100%;
   }
   .sm-width100{
    width: 100%!important;
   }
   .left5{
        margin-left: 6px!important;
   }
   .right2{
        margin-right: 3px !important;
   }
   .marginAuto{
        margin: auto!important;
   } */
   .pig-home-banner{
        margin-left: 20px!important;
      
        display: flex;
        justify-content: center;
        text-align: center;  
        width: 100%!important;
        padding-top: 10px !important;
        padding-bottom: 10px!important;
   }
   .pig-content{
    padding:0!important;
   }

} 
.price-set{
     margin-top:50px;
}

@media only screen and (min-width: 772px){
     .price-set{
          margin-top:100px;
     }   
}
@media only screen and (min-width: 772px){

.pig-home-banner {
     margin-left:20px;
}
}

.mobile-fixed-menu {
     color: #fff;
     background-color: #ab408a;
     border: none;
     outline: none;
     box-shadow: 0 0 5px rgba(0,0,0,.2),inset 0 -3px 5px 2px rgba(134,50,108,.5),inset 0 3px 5px 0 rgba(208,129,184,.5);
     position: fixed;
     top: 190px;
     right: 0;
     left: auto;
     bottom: auto;
     flex-direction: column;
     border-radius: 17px 0 0 17px;
     padding: 0;
     width: 145px;
     z-index: 3
 }
 .mobile-fixed-menu img{

     margin: 10px auto 7px;
     display: table;
     width: auto;
     height: 28px;
 }
 .mobile-fixed-menu-row {
     display: flex;
     flex-wrap: wrap;
     margin-right: -15px;
     margin-left: -15px;
 }
 .mobile-fixed-menu a {
     color: white;
     align-items: center;
     text-align: center;
     font-size: 13px;
     line-height: 16px;
     margin-bottom: 13px;
 }
 .sectiontwo-text-dark span p{
     /* background:#4a4a4a; */
     color:red;
 }
 @media only screen and (min-width: 772px){

    .prie-and-pig-circle{
        display:none;
    }
 }
@media only screen and (max-width: 772px){
     
     .contact-relative-contactus{
          position: relative;
          
           }
              .contact-absolute-contactus{
                  width: 130px;
                  position: relative;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
              
              }
    .mobile-fixed-menu {
         top: auto;
    }
    .mobile-fixed-menu {
     position: fixed;
     border-radius: 0;
     box-shadow: none;
     bottom: 0;
     top: auto;
     right: auto;
     width: 100%;
     border-top: 1px solid #cebade;
     display: flex;
     text-align: center;
     padding: 0;
 }
.form-css-hidden{
     display:none;
}
}
@media only screen and (max-width: 992px){
.button-show-and-hide{
     display:none;
}
.card-body-margin{
   
    margin-top: 60px;
}
}
@media only screen and (min-width: 767px){
     .offer-circle {
          position: relative;
          border: 1px solid #684286;
          background: #fff;
          padding: 2px;
          border-radius: 50%;
          width: 138px;
          height: 138px;
         
          left: 20px;
     }
   
}
.total-price-single-teatments{
     font-size: 14px;
     line-height: 133%;
     padding-top: 10px;
     color: #535353;
     position: absolute;
     top: -70px;
     left: -25px;
     display: block;
     width: 102px;
     height: 102px;
     border-radius: 51px;
     border: 4px solid #d4a54e;
     text-align: center;
     background: #fff;
     transition: all .3s
}
@media only screen and (max-width: 500px){
    .price-from-section34{
        margin-left: 35%;
     }
     .total-price-single-teatments{
          left: 50%;
          top: 60%;
          transform: translate(-50%, -50%);
}
     .page-home .main-section .section-bg-overlay .container .row.home-panel h1.h2, 
     .page-home .main-section .section-bg-overlay .container .row.home-panel h2.h2.h1, 
     .page-home .main-section .section-bg-overlay .container .row.home-panel h3.h2.h1, 
     .page-home .main-section .section-bg-overlay .container .row.home-panel h4.h2.h1, 
     .page-home .main-section .section-bg-overlay .container .row.home-panel h5.h2.h1, 
     .page-home .main-section .section-bg-overlay .container .row.home-panel h6.h2.h1 {
          text-align: center;
     }
}
.strong-privecypolicy-page strong{
     color: #4a4a4a;
     font-weight: 600;

}
.hover-underline-animation a {
     /* display: inline-block; */
     
     position: relative;
     /* color: #0087ca; */
   }
 
 .hover-underline-animation a::after {
     content: '';
     position: absolute;
     width: 100%;
     transform: scaleX(0);
     height: 2px;
     bottom: 0;
     left: 0;
     background-color: white;
     transform-origin: bottom right;
     /* transition: transform 0.25s ease-out; */
     border-bottom: 1px solid #4a4a4a;
   }
   
   .hover-underline-animation a:hover::after {
     transform: scaleX(1);
     transform-origin: bottom left;
   }
   @media (max-width: 991.98px){
     .layout-footer .col-md-3:last-child {
          width: 100%;
          order: 1;
      }
}
@media only screen and (max-width: 500px){
     .tarmandcondition-hide{
          display:none;
     }
     .card-body-teatments h3{
          padding: 0 0 15px;
          width: 50%;
     }
     .card-body-single-teatments h3 {
          padding: 0 0;
          /* width: 50%; */
          height: 150px;
          font-size: 20px;
          padding-top: -10px;
      }
     .footer-row-div {
          display: flex;
          /* background-color: red; */
     }
}

.transform-card{
    transform: translate(0%, 11%) 
   }

@media only screen and (max-width: 767px){
   .transform-card{
     transform: translate(0%, 11%) 
    }
     .layout-footer ul li {
         font-size: 14px;
         line-height: 23px;
         margin: 0;
     }
h1, h1.h1, h2.h1, h3.h1, h4.h1, h5.h1, h6.h1 {
    text-align: center;
    font-size: 23px;
    line-height: 29px;
}
.btn {
    /* padding: 10px 20px; */
    font-size: 14px;
}}

.pricing-page-circle-circle{ 
     text-align: center;
     background: #fff;
     width: 100%;
     border-radius: 50%;
     color: #4a4a4a;
     position: relative;
     box-shadow: 0 0 0 33px hsl(0deg 0% 100% / 40%);
 }
.pricing-page-circle-2{
     position: absolute;
     width: 100%;
     height: 100%;
     font-weight: 400;
     border-radius: 50%;
     box-shadow: 0 0 0 18px hsl(0deg 0% 100% / 40%);
     display: flex;
     flex-direction: column;
     justify-content: center;
     padding: 0 15px;
}
@media (max-width: 767.98px){
.page-treatment-reviews .main-section p {
    text-align: center!important;
}
.main-section h1, body.entry-7031 .main-section h2.h1, body.entry-7031 .main-section h3.h1, body.entry-7031 
.main-section h4.h1, body.entry-7031 .main-section h5, body.entry-7031 .main-section h6.h1, body.entry-7031 .main-section p {
     color: #fff;
 }
.review-div-center{
     text-align: center;
 }
 
}
.layout-main-review{
    
      background-color: #9c9da6;
     
}
.review-div-center-span  p{
    
     color:#fff;    
}
.dentist-patner-page-price p  {
    color:#fff;   
}
.card.card-gold span.year-price {
    font-size: 34px;
    line-height: 21px;
    font-weight: 400;
    padding: 0 0 12px;
}
.card.card-gold span.year-price-pm-top {
    font-size: 21px;
    line-height: 24px;
    font-weight: 400;
    padding: 0 0 3px;
}
.card.card-gold span.year-price-pm {
    font-size: 21px;
    line-height: 24px;
    font-weight: 400;
    padding: 0 0 12px;
}
.dentist-patner-page-price  p:nth-of-type(1) {
    margin-bottom: 10px;
    font-size: 34px;
    line-height: 21px;
    font-weight: 600;
    /* padding: 0 0 12px; */
  }
  .dentist-patner-page-price  p:nth-of-type(2){
    margin-bottom: 10px;
  }
  .dentist-patner-page-price  p:nth-of-type(2), 
   .dentist-patner-page-price  p:nth-of-type(3)  {
    font-size: 21px;
    /* line-height: 24px; */
    font-weight: 500;
    /* padding: 0 0 3px; */
  }
  .dentist-patner-page-price  p:nth-of-type(4),
  .dentist-patner-page-price  p:nth-of-type(5),
  .dentist-patner-page-price  p:nth-of-type(6)  {
    margin-bottom: 3px;
    font-size: 18px;
    /* line-height: 24px; */
    font-weight: 400;
    /* padding: 0 0 12px; */
  }
.review-div-center-span span p ,.text-color{
  
     color:#ffff;
}
@media only screen and (max-width: 765px) and (min-width: 600px) {
    .page-dentists-near-me .price-page-circle{
        display: none;
    }
}
@media (max-width: 767.98px){
.review-div-h1 h1, h1.h1, h2.h1, h3.h1, h4.h1, h5.h1, h6.h1 {
    text-align: center;
    font-size: 23px;
    line-height: 29px;
}
h1.page-title.mb-3 {
     color: #253959;
     font-size: 23px;
     line-height: 29px;
 }

}

/* ////////////////////// dentist near me  page css    /////////////////////////// */
.col,.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12,.col-auto,.col-lg,.col-lg-1,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-auto,.col-md,.col-md-1,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12,.col-md-auto,.col-sm,.col-sm-1,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-auto {
     position: relative;
     width: 100%;
     padding-right: 15px;
     padding-left: 15px
 }
 .container-test-page {
     background-repeat: no-repeat;
     background-position: 100%;
     background-size: contain;
     padding-top: 66px;
     padding-bottom: 21px;
 }
 .page-partners .dentist-banner {
     /* background: #674086  no-repeat; */
     background-size: cover
 }
 .page-partners .page-title {
     font-weight: 400;
     color: #fff
 }
 .card.card-gold {
     color: #fff;
     background: #d4a54e;
     padding: 1.25rem 0.4rem;
 }
 .card.card-headline-pricing {
     font-size: 16px;
     text-align: center;
     justify-content: center;
 }
 .card.card-gold img {
     max-height: 52px;
     margin: 0 0 20px;
     width: auto;
 }
 
 .card.partner-banner-cards img {
     max-height: 50px;
     margin: 0 0 14px;
     width: auto;
     position: relative;
     left: 50%;
     top: 15%;
     transform: translate(-50%, -50%);
 }
 .card.card-gold {
     color: #fff;
     background: #d4a54e;
     padding: 1.25rem 0.4rem;
 }
 .card.card-headline {
     font-size: 19px;
     color: #646664;
     text-align: center;
     font-weight: 400;
     line-height: 130%;
 }
 .card.partner-banner-cards {
     align-items: center;
 }
 .card {
     border-radius: 7px;
     box-shadow: 0 3px 7px rgba(0,0,0,.25);
     transition: all .3s ease-in-out;
 }
 .card.card-headline h4 {
     padding: 0;
     margin: 0;
 }
 .card.card-gold img {
    
     position: relative;
     max-height: 52px;
     margin: 0 0 20px;
     width: auto;
     top: 8%;
     left: 50%;
     transform: translate(-50%, -50%);
 }
 .card.card-gold span.year-price {
     font-size: 34px;
     line-height: 21px;
     font-weight: 400;
     padding: 0 0 12px;
 }
 .card.card-gold span.year-price-pm-top {
     font-size: 21px;
     line-height: 24px;
     font-weight: 400;
     padding: 0 0 3px;
 }
 .card.card-gold span.year-price-pm {
     font-size: 21px;
     line-height: 24px;
     font-weight: 400;
     padding: 0 0 12px;
 }
 .card.card-headline-pricing span {
     display: block;
     line-height: 120%;
 }
 .test-page-price-circle-p-big{
     padding: 40px;
     display: flex;
     position: absolute;
     top: -150px;
     right: -100px;
     width: 530px;
     height: 530px;
 }
 .test-page-price-circle {
     box-shadow: 0 0 0 60px hsla(0,0%,100%,.4);
     text-align: center;
     background: #fff;
     width: 100%;
     border-radius: 50%;
     color: #4a4a4a;
     position: relative;
 }
 .test-page-price-circle-content {
     box-shadow: 0 0 0 30px hsla(0,0%,100%,.4);
     overflow: hidden;
     display: flex;
     flex-direction: row;
     justify-content: normal;
     padding: 52px 40px 10px;
     position: absolute;
     width: 100%;
     height: 100%;
     font-weight: 400;
     border-radius: 50%;
     background: #fff;
 }
 .test-page-price-demonstration {
     box-shadow: none;
     border: none;
     align-self: center;
     position: relative;
     top: -15px;
 }
 .test-page-price-demonstration-text{
     color: #253959;
     font-size: 23px;
     line-height: 28px;
 }
 .pencil-wrapper-test-page{
     position: relative;
 }
 .pencil-wrapper-test-page:after {
     font-family: Font Awesome\ 5 Free;
     content: "";
     font-weight: 900;
     position: absolute;
     top: 6px;
     right: 6px;
     width: 20px;
     height: 20px;
     display: block;
     color: #684286;
     font-size: 18px;
 }
 .page-partners .dentist-banner .demonstration-form {
     box-shadow: none;
     border: none;
     align-self: center;
     position: relative;
     top: -15px;
 }
 .page-partners .dentist-banner .demonstration-form h3 {
     color: #253959;
     font-size: 23px;
     line-height: 28px;
 }
 @media (max-width: 1199.98px) {
     .page-partners .dentist-banner .boxes-block-banner .col-xl-8 {
         order:2
     }
    
 }
 
 @media (max-width: 1199.98px) {
    
     .test-page-price-circle-p-big{
         top:-10px;
         right: 0;
         width: 100%;
         height: 101%;
         position: relative
     }
     .test-page-price-circle {
         border-radius:7px;
         box-shadow: 0 0 0 30px hsla(0,0%,100%,.4)
     }
 
     .test-page-price-circle-content {
         border-radius:7px;
         padding: 20px;
         box-shadow: 0 0 0 15px hsla(0,0%,100%,.4)
     }
     .page-partners .dentist-banner .demonstration-form {
         top:0
     }
 
 }
 
 @media (max-width: 767.98px) {
     .test-page-price-circle-p-big {
         height:500px;
         padding: 20px;
         top: 0;
         margin-bottom: 20px
     }
     .test-page-price-circle {
         box-shadow:0 0 0 15px hsla(0,0%,100%,.4)
     }
     .test-page-price-circle-content {
         box-shadow:0 0 0 7px hsla(0,0%,100%,.4);
         justify-content: center
     }
    
 }
 @media (max-width: 1199.98px) {
     .page-partners .dentist-banner .boxes-block-banner .col-xl-4 {
         order:1
     }
 }
 
 .page-partners .dentist-banner .demonstration-form {
     box-shadow: none;
     border: none;
     align-self: center;
     position: relative;
     top: -15px
 }
 
 @media (max-width: 1199.98px) {
     .page-partners .dentist-banner .demonstration-form {
         top:0
     }
 }
 
 @media (max-width: 1199.98px) {
     .page-partners .dentist-banner .demonstration-form .card-body {
         padding:1.25rem 0
     }
 }
 
 .page-partners .dentist-banner .demonstration-form h3 {
     color: #253959;
     font-size: 23px;
     line-height: 28px
 }
 
 @media (max-width: 1199.98px) {
     .page-partners .dentist-banner .demonstration-form h3 {
         font-size:20px;
         line-height: 25px;
         margin-bottom: 0
     }
 }
 
 .page-partners .dentist-banner .demonstration-form .btn.btn-pink {
     display: table!important;
     margin-left: auto;
     margin-right: auto
 }
 
 .page-partners .dentist-banner .p-big-circle {
     padding: 40px;
     display: flex;
     position: absolute;
     top: -150px;
     right: -100px;
     width: 530px;
     height: 530px
 }
 
 @media (max-width: 1199.98px) {
     .page-partners .dentist-banner .p-big-circle {
         top:-10px;
         right: 0;
         width: 100%;
         height: 101%;
         position: relative
     }
 }
 
 @media (max-width: 767.98px) {
     .page-partners .dentist-banner .p-big-circle {
         height:400px;
         padding: 20px;
         top: 0;
         margin-bottom: 20px
     }
 }
 
 .page-partners .dentist-banner .p-big-circle .circle.circle-1 {
     box-shadow: 0 0 0 60px hsla(0,0%,100%,.4);
     text-align: center;
     background: #fff;
     width: 100%;
     border-radius: 50%;
     color: #4a4a4a;
     position: relative
 }
 
 @media (max-width: 1199.98px) {
     .page-partners .dentist-banner .p-big-circle .circle.circle-1 {
         border-radius:7px;
         box-shadow: 0 0 0 30px hsla(0,0%,100%,.4)
     }
 }
 
 @media (max-width: 767.98px) {
     .page-partners .dentist-banner .p-big-circle .circle.circle-1 {
         box-shadow:0 0 0 15px hsla(0,0%,100%,.4)
     }
 }
 
 .page-partners .dentist-banner .p-big-circle .circle.circle-1 .content {
     box-shadow: 0 0 0 30px hsla(0,0%,100%,.4);
     overflow: hidden;
     display: flex;
     flex-direction: row;
     justify-content: normal;
     padding: 52px 40px 10px;
     position: absolute;
     width: 100%;
     height: 100%;
     font-weight: 400;
     border-radius: 50%;
     background: #fff
 }
 
 @media (max-width: 1199.98px) {
     .page-partners .dentist-banner .p-big-circle .circle.circle-1 .content {
         border-radius:7px;
         padding: 20px;
         box-shadow: 0 0 0 15px hsla(0,0%,100%,.4)
     }
 }
 
 @media (max-width: 767.98px) {
     .page-partners .dentist-banner .p-big-circle .circle.circle-1 .content {
         box-shadow:0 0 0 7px hsla(0,0%,100%,.4);
         justify-content: center
     }
 }
 
 .page-partners .dentist-banner .p-big-circle .circle.circle-1 .content .image-holder-pig {
     margin-right: 10px
 }
 
 .page-partners .dentist-banner .p-big-circle .circle.circle-1 .content p.main-strapline {
     font-size: 19px;
     line-height: 23px;
     text-align: left;
     color: #253959;
     margin-bottom: 0
 }
 
 .page-partners .dentist-banner .p-big-circle .circle.circle-1 .content img.bottom {
     position: absolute;
     clear: both;
     left: 0;
     right: 0;
     bottom: -30%;
     -o-object-fit: contain;
     object-fit: contain;
     -o-object-position: center;
     object-position: center;
     width: 100%
 }
 
 @media (max-width: 1199.98px) {
     .page-partners .dentist-banner .p-big-circle .circle.circle-1 .content form .form-group {
         margin-bottom:7px
     }
 }
 
 @media (max-width: 1199.98px) {
     .page-partners .dentist-banner .p-big-circle .circle.circle-1 .content form .form-group input {
         font-size:14px
     }
 }
 
 .page-partners .dentist-banner .p-big-circle .circle.circle-1 .content form .form-group label {
     font-weight: 200
 }
 
 @media (max-width: 1199.98px) {
     .page-partners .dentist-banner .p-big-circle .circle.circle-1 .content form .form-group label {
         font-size:14px
     }
 }
 
 .page-partners .dentist-banner .p-big-circle .circle.circle-1 .content form .col-12 {
     padding: 0 10px
 }
 .test-page-text-image{
     display: flex;
     gap: 5px;
     align-items: center;
    justify-content: center;
}
 .test-page-image {
     max-width: 216px;
     width: 100%;
     position: relative;
     margin: 0 4px;
     top: -3px;
 }
 .table-head-imge{
     max-width: 120px;
     margin: auto;
 }
 
 @media only screen and (max-width: 992px){
     .test-page-image {
        max-width: 160px;
        margin: 0 2px;
    }
    }




    /* satish css */

    /* Is my clinic eligible? 5 number section css */

.eligible-img-fluid{
    max-height: 50px;
    width: 50px;
    margin: 0 0 20px;
    position: relative;
    left: 45%;
    /* top: 50%; */
    /* transform: translate(-50%,-50%); */
}

.eligible-img-fluid2{
    max-height: 100px;
    width: 120px;
    margin: 0 0 20px;
    position: relative;
    left: 35%;
    /* top: 50%; */
    /* transform: translate(-50%,-50%); */
}
/* .eligible-img-eligible{
    max-height: 130%;
    max-width: 115%;
} */

.star-position{
    position: relative;
    left: 45%;
    transform: translate(-50%,-50%);
    margin: 24px 0;
}
h3.gold {
    color: #d4a54e!important;
}

@media screen and (min-width:778px){
    .gold img{
position: relative;
max-width: 400px;
/* margin-left: 30px; */
left: 14%;
      
    }

    .partners.guarantee h3.gold {
        margin-bottom: 28px;
        margin-top: 0;
        font-size: 45px;
        /* line-height: 30px; */
    }
}
.h3-gold-price{
    font-size: 36px;
    font-weight: 700;
    /* line-height: 40px; */
}
@media screen and (max-width:991px) {
    .partners.guarantee h3.gold {
        margin-bottom: 28px;
        margin-top: 0;
        font-size: 41px;
        line-height: 15px;
    }
    .login-signup-page {
        display: none;
    }
}


.login-signup-page{
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;

}



.partners .circle-holder {
    background: rgba(104,66,134,.25);
    border-radius: 50%;
    padding: 10px;
    width: 181px;
    height: 181px;
    display: flex;
    justify-content: center;
    margin: auto auto 20px;
}

@media screen and (min-width:992px) {
    .iphone-img{
        max-width: 550px;
        height: 550px;
    }
    .ipad-img{
        max-width: 550px;
        height: 550px;
    }
    .card-flex-stories{
        display: flex;
    }
    .view-all-pages{
        display: none;
    }

    .login-signup-mobile{
        display: none;
    }
}


/* forth secton logo img */
.price-img{
max-width: 220px;
display: inline;
margin-left: 8px;
margin-right: 8px;
}




.partners .circle-holder .circle-inner .inner {
    background: #fff;
    border-radius: 50%;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.partners .line {
    width: 132px;
    height: 47px;
    border-bottom: 2px solid red;
    -webkit-transform: translateY(-11px) translateX(-10px) rotate(-22deg);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
} 

.partners .circle-holder{
    background: rgba(104,66,134,.25);
    border-radius: 50%;
    padding: 10px;
    width: 181px;
    height: 181px;
    display: flex;
    justify-content: center;
    margin: auto auto 20px;
}
.partners  .circle-inner{
    background: rgba(104,66,134,.5);
    border-radius: 50%;
    padding: 10px;
    display: flex;
    width: 100%;

}




.partners .circle-holder .circle-inner .inner h3 {
    font-size: 36px;
    font-weight: 400;
    padding-top: 7px;
}




.partners .card p.red {
    color: red;
    font-weight: 600;
    padding: 10px 0 0;
    font-size: 17px;
}

.partners ul{
    color: #253959;
    font-size: 16px;
    font-weight: 400;
    list-style: none;
    margin: 0 0 9px;
    padding: 0;

}
.partners .card ul li {
    color: #253959;
    font-size: 16px;

    list-style: none;
    margin: 0 0 9px;
    padding: 0;
}
.partners .card ul li strong {
    font-size: 27px;
    font-weight: bold;
    position: relative;
    font-weight: 600;
    top: 2px;
}

    .partners .card ul li {
    font-weight: 600;
}

.text-left-alexandra_dental span p{
    text-align: left;
}

/* //////////////////////////////////  Blog Css    ///////////////////////////////////////// */

.gray-bg {
    background-color: #f5f5f5;
}
/* Blog 
---------------------*/
.blog-grid {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
}
.blog-grid .blog-img {
  position: relative;
}
.blog-grid .blog-img .date {
  position: absolute;
  background: #fc5356;
  color: #ffffff;
  padding: 8px 15px;
  left: 10px;
  top: 10px;
  border-radius: 4px;
}
.blog-grid .blog-img .date span {
  font-size: 22px;
  display: block;
  line-height: 22px;
  font-weight: 700;
}
.blog-grid .blog-img .date label {
  font-size: 14px;
  margin: 0;
}
.blog-grid .blog-info {
  padding: 20px;
}
.blog-grid .blog-info h5 {
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 10px;
}
.blog-grid .blog-info h5 a {
  color: #20247b;
}
.blog-grid .blog-info p {
  margin: 0;
}
.blog-grid .blog-info .btn-bar {
  margin-top: 20px;
  /* color: #20247b; */
}
.blog-grid .blog-info .btn-bar:hover{
  text-decoration: underline;
  color: #6a71f5;
}


/* Blog Sidebar
-------------------*/
.blog-aside .widget {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.blog-aside .widget-body {
  padding: 15px;
}
.blog-aside .widget-title {
  padding: 15px;
  border-bottom: 1px solid #eee;
}
.blog-aside .widget-title h3 {
  font-size: 20px;
  font-weight: 700;
  /* color: #fc5356; */
  color: gray;
  margin: 0;
}
.blog-aside .widget-author .media {
  margin-bottom: 15px;
}
.blog-aside .widget-author p {
  font-size: 16px;
  margin: 0;
}
.blog-aside .widget-author .avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}
.blog-aside .widget-author h6 {
  font-weight: 600;
  color: #20247b;
  font-size: 22px;
  margin: 0;
  padding-left: 20px;
}
.blog-aside .post-aside {
  margin-bottom: 15px;
}
.blog-aside .post-aside .post-aside-title h5 {
  margin: 0;
}
.blog-aside .post-aside .post-aside-title a {
  font-size: 18px;
  color: #20247b;
  font-weight: 600;
}
.blog-aside .post-aside .post-aside-meta {
  padding-bottom: 10px;
}
.blog-aside .post-aside .post-aside-meta a {
  color: #6F8BA4;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
}
.blog-aside .latest-post-aside + .latest-post-aside {
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-top: 15px;
}
.blog-aside .latest-post-aside .lpa-right {
  width: 90px;
}
.blog-aside .latest-post-aside .lpa-right img {
  border-radius: 3px;
}
.blog-aside .latest-post-aside .lpa-left {
  padding-right: 15px;
}
.blog-aside .latest-post-aside .lpa-title h5 {
  margin: 0;
  font-size: 13px;
  white-space: nowrap; 
  width: 200px; 
  overflow: hidden;
  text-overflow: ellipsis; 
}
.blog-aside .latest-post-aside .lpa-title a {
  color: #20247b;
  font-weight: 600;
}
.blog-aside .latest-post-aside .lpa-meta a {
  color: #6F8BA4;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
}

.tag-cloud a {
  padding: 4px 15px;
  font-size: 13px;
  color: #ffffff;
  background: #20247b;
  border-radius: 3px;
  margin-right: 4px;
  margin-bottom: 4px;
}
.tag-cloud a:hover {
  background: #fc5356;
}

.blog-single {
  padding-top: 30px;
  padding-bottom: 30px;
}

.article {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  padding: 15px;
  margin: 15px 0 30px;
}
.article .article-title {
  padding: 15px 0 20px;
}
.article .article-title h6 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}
.article .article-title h6 a {
  text-transform: uppercase;
  color: #fc5356;
  border-bottom: 1px solid #fc5356;
}
.article .article-title h2 {
  color: #20247b;
  font-weight: 600;
}
.article .article-title .media {
  padding-top: 15px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 20px;
}
.article .article-title .media .avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}
.article .article-title .media .media-body {
  padding-left: 8px;
}
.article .article-title .media .media-body label {
  font-weight: 600;
  color: #fc5356;
  margin: 0;
}
.article .article-title .media .media-body span {
  display: block;
  font-size: 12px;
}
.article .article-content h1,
.article .article-content h2,
.article .article-content h3,
.article .article-content h4,
.article .article-content h5,
.article .article-content h6 {
  color: #20247b;
  font-weight: 600;
  margin-bottom: 15px;
}
.article .article-content blockquote {
  max-width: 600px;
  padding: 15px 0 30px 0;
  margin: 0;
}
.article .article-content blockquote p {
  font-size: 20px;
  font-weight: 500;
  color: #fc5356;
  margin: 0;
}
.article .article-content blockquote .blockquote-footer {
  color: #20247b;
  font-size: 16px;
}
.article .article-content blockquote .blockquote-footer cite {
  font-weight: 600;
}
.article .tag-cloud {
  padding-top: 10px;
}

.article-comment {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  padding: 20px;
}
.article-comment h4 {
  color: #20247b;
  font-weight: 700;
  margin-bottom: 25px;
  font-size: 22px;
}
img {
    max-width: 100%;
}
img {
    vertical-align: middle;
    border-style: none;
}








/* .blog-post .blog-info .date div.number {
padding:4px 10px;
color:#FFFFFF;
font-size:20px;
} */

.box {
  /* background-color:#FFFFFF; */
padding:10px 20px;
box-shadow:0 1px 2px 0 rgba(0,0,0,0.1);
margin-bottom:20px;
}

/* .box h3 {
  margin:30px 0 5px;
font-weight:bold;
} */

.box ul {
margin:0;
}

.box ul li {
font-size:13px;
border-bottom:1px dashed #DDDDDD;
padding:10px 0;
font-weight:600;
}
.box ul li:hover{
  text-decoration: underline;
}

.box ul li:last-child {
border-bottom:0;
}


.box.categories ul li i {
color:#BBBBBB;	
position:relative;
top:2px;
width:20px;
}














/* /////////// thank you page /////////////// */
.bg-light-blue {
    background: #e8faff;
    color: #3d3d3d;
}

.static-page-main-section .page-title {
    color: #1eceff;
    text-align: center;
}
.bg-blue {
    background: #31b0e4;
    color: #fff;
}
.section-title {
    text-align: center;
    margin-bottom: 25px;
}

.reviews-section .introduction {
    font-size: 17px;
}
.reviews .patient-name {
    font-style: italic;
    font-weight: 600;
    margin: 0;
    padding-left: 35px;
    padding-top: 0;
    padding-bottom: 0;
}

.reviews .review {
    margin-bottom: 10px;
    background: url("https://myclinique.net/images/quote-open-white.png?f6d47f602eb355f2763c261e218bb2f1") 0 0 no-repeat
    ,url('https://myclinique.net/images/quote-close-white.png?f6d47f602eb355f2763c261e218bb2f1') 100% 100% no-repeat;
    background-size: 30px;
    padding: 30px 35px;
    -webkit-margin-after: 0;
    margin-block-end:0
}
.eyesIcon{
    position: absolute;
    z-index: 10;
    float: left;
   
    margin-top: 5px;
    right: 7%;
}
.preview-image-treatments {
    text-align: center;
    height: 120px;
}
 .preview-image-treatments img {
    text-align: left;
    border-bottom-left-radius: 6px;
    height: 100%;
    width: auto;
}

 .treatment-header-image img {
    display: table;
    position: relative;
    margin: auto;
    max-width: 540px;
    width: 150%
}
.treatment-header-image .monthly-price-from {
    background: #31b0e4;
    color: #fff;
    border-radius: 50%;
    border: 3px solid #fff;
    text-align: center;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 19px;
    display: flex;
    flex-direction: column;
    justify-content: center
}

@media screen and (max-width:767px) {
    .treatment-header-image img {
        width: 100%;
        max-width: 300px;
        display: table;
        position: relative;
        margin: auto;
      
       
    }

 /* .monthly-price-from {

 } */

}

.icon-container-myclinic {
    margin-bottom: 15px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
}
    .icon-container-myclinic img  { 
    height: 100%;
    width: auto;
}

.login-page-web {
    color: red;
    font-size: 15px;
    position: relative;
    left: 5px;
}

.treatment-main-section {
    background: linear-gradient(180deg,transparent 200px,hsla(0,0%,100%,.3) 0 0,hsla(0,0%,100%,.6)),#6c6c6c url('https://myclinique.net/images/grey-texture.png?fa92e8e926de9afb2022b0dc8afbbc8c') repeat top;
    color: #fff;
    padding: 35px 0 0;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}



.treatment-header-image {
    position: relative;
    margin-bottom: 15px;
    max-width: 500px;
    height: auto;
    margin-left: 20px;
}
/* .treatment-header-image .monthly-price-from {
    background: #31b0e4;
    color: #fff;
    border-radius: 50%;
    border: 3px solid #fff;
    text-align: center;
    width: 180px;
    height: 180px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 23px;
    display: flex;



    flex-direction: column;
    justify-content: center;
} */

.treatment-main-section .card.card-body.card-layered {
    position: absolute;
    bottom: 0;
    margin: 0 15px 25px 0;
}

.treatment-header-reviews {
    margin-top: 20px;
    text-align: center;
    color: #3d3d3d;
}

.treatment-header-reviews .star-rating {
    margin-bottom: 15px;
}

.treatment-header-reviews .review {
    line-height: 125%;
    font-style: italic;
    padding: 24px 30px;
    background: url('https://myclinique.net/images/quote-open-white.png?e23bfcd521687aa1eec6941c80886b09') 0 0 no-repeat,url('https://myclinique.net/images/quote-close-white.png?f6d47f602eb355f2763c261e218bb2f1') 100% 100% no-repeat;
    background-size: 25px;
    margin-bottom: 0;
}

.treatment-header-reviews .patient-name {
    font-weight: 600;
}

.headline-card {
    color: #3d3d3d;
    text-align: center;
}
.treatment-main-section .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.headline-card .icon-container {
    margin-bottom: 15px;
    height: 47px;
}

.headline-card .icon-container img {
    height: 100%;
    width: auto;
}
.treatment-header-image.mobile {
    max-width: 360px;
}
@media (min-width: 992px){
.mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem!important;
}
 .treatment-header-image .monthly-price-from {
    font-size: 20px;
    width: 125px;
    height: 125px;
    right: 10%;
}

.monthly-price-from-display{
    display: none;
} 
}

@media (max-width:992px) {
    .monthly-price-from-none{
        display: none;
    }
   
}

.treatment-main-section .card.card-body.card-layered {
    position: absolute;
    bottom: 0;
    margin: 0 15px 25px 0;
}

.card.card-layered {
    margin: 25px 10px;
    box-shadow: 0 0 0 10px hsla(0,0%,100%,.3);
}
.imae-form{
    text-align: center;
}
.treatment-main-section .form .form-logo {
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
}

.add-image-new  {
  
    height: 390px;
}

@media (max-width: 767px){
.add-image-new  {
  
    height: 310px;
}
.price-circal-new{
    top: 45%;
}
}









/* new blog css */

.slick-slide.image-box {
    margin-bottom: 20px;
  }
  
  .image-box .body {
    padding: 15px;
  }
  
  .image-box .body h3 {
    margin-top: 0;
  }
  
  .image-box .body p:last-child {
    margin-bottom: 0;
  }
  
  .image-box.style-4 {
    border: none;
  }
  
  .image-box.style-3-b {
    display: block;
    margin-bottom: 40px;
  }
  
  .image-box.style-3-b .body {
    padding: 10px 0;
  }
  
  .image-box--shadowed {
    -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  
  .image-box--shadowed:hover {
    -webkit-box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  }
  
  /* Medium devices (tablets, laptops, smartphones 768px and up) */
  @media (min-width: 768px) {
    .image-box.style-3-b .body {
      padding: 0;
    }
  }
  
  /* Medium devices (desktops, tablets less than 991px) */
  @media (max-width: 991px) {
    .image-box.style-4 {
      margin-bottom: 30px;
    }
  }
  
  .white-bg {
      background-color: #ffffff;
  }
  
  /* Social icons block
  ---------------------------------- */
  .social-links {
    list-style: none;
    font-size: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 0;
  }
  
  .social-links li {
    display: inline-block;
    margin: 0 2px 2px 0;
  }
  
  .social-links li a {
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    font-size: 18px;
    border: 1px solid #e7e7e7;
    color: #cdcdcd;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  
  .social-links li a i {
    line-height: 40px;
  }
  
  .social-links li a:hover {
    background-color: transparent;
  }
  
  .social-links.animated-effect-1 li a {
    -webkit-transition: color 0.4s;
    -o-transition: color 0.4s;
    transition: color 0.4s;
    position: relative;
    z-index: 1;
  }
  
  .social-links.animated-effect-1 li a:hover {
    color: #ffffff !important;
    -webkit-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
  }
  
  .social-links.animated-effect-1 li a:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    content: '';
    top: 0px;
    left: 0px;
    padding: 0px;
    z-index: -1;
    -webkit-transition: -webkit-transform 0.4s, opacity 0.4s, background-color 0.4s;
    -moz-transition: -moz-transform 0.4s, opacity 0.4s, background-color 0.4s;
    transition: transform 0.4s, opacity 0.4s, background-color 0.4s;
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  
  .social-links.animated-effect-1 li a:hover:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transition: -webkit-transform 0s, opacity 0.3s, background-color 0.3s;
    -moz-transition: -moz-transform 0s, opacity 0.3s, background-color 0.3s;
    transition: transform 0s, opacity 0.3s, background-color 0.3s;
  }
  
  .social-links.animated-effect-1:not(.circle) li a:after {
    -webkit-border-radius: 0%;
    -moz-border-radius: 0%;
    border-radius: 0%;
  }
  
  .social-links.default li a {
    color: #ffffff;
  }
  
  .social-links.default li a:hover {
    background-color: #ffffff;
    color: #777777;
  }
  
  .dark .social-links li a {
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #ffffff;
  }
  
  .dark.social-links li a {
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #ffffff;
  }
  
  .dark.social-links li a:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    border-color: rgba(0, 0, 0, 0.8);
  }
  
  .colored .social-links li a {
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.01);
    color: #ffffff;
  }
  
  .header-top .social-links {
    margin: 0;
    display: inline-block;
    margin-top: 2px;
  }
  
  .footer-content .social-links li {
    margin: 0 5px 5px 0;
  }
  
  .footer-content .social-links li a {
    color: #777777;
    background-color: transparent;
    border-color: #777777;
  }
  
  .footer-content .social-links li a:hover {
    color: #ffffff;
    background-color: #777777;
    border-color: #777777;
  }
  
  .text-white .social-links li a {
    color: #ffffff;
    border-color: #ffffff;
  }
  
  .text-white .social-links li a:hover,
  .text-white .social-links li a:focus {
    color: #333333;
    background-color: #ffffff;
    border-color: #ffffff;
  }
  
  .social-links.small li a {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  
  .social-links.small li a i {
    line-height: 29px;
  }
  
  .social-links.large li a {
    width: 60px;
    height: 60px;
    font-size: 24px;
  }
  
  .social-links.large li a i {
    line-height: 60px;
  }
  
  .social-links.square li a,
  .square {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  
  .social-links.circle li a {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
  }
  
  .social-links.animated-effect-1 li.twitter a:hover {
    border-color: #55acee;
  }
  
  .social-links:not(.animated-effect-1) li.twitter a:hover,
  .colored.social-links li.twitter a,
  .social-links.animated-effect-1 li.twitter a:after,
  .btn.twitter {
    background-color: #55acee;
    border-color: #55acee;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.skype a:hover {
    border-color: #00aff0;
  }
  
  .social-links:not(.animated-effect-1) li.skype a:hover,
  .colored.social-links li.skype a,
  .social-links.animated-effect-1 li.skype a:after,
  .btn.skype {
    background-color: #00aff0;
    border-color: #00aff0;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.linkedin a:hover {
    border-color: #0976b4;
  }
  
  .social-links:not(.animated-effect-1) li.linkedin a:hover,
  .colored.social-links li.linkedin a,
  .social-links.animated-effect-1 li.linkedin a:after,
  .btn.linkedin {
    background-color: #0976b4;
    border-color: #0976b4;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.googleplus a:hover {
    border-color: #dd4b39;
  }
  
  .social-links:not(.animated-effect-1) li.googleplus a:hover,
  .colored.social-links li.googleplus a,
  .social-links.animated-effect-1 li.googleplus a:after,
  .btn.googleplus {
    background-color: #dd4b39;
    border-color: #dd4b39;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.youtube a:hover {
    border-color: #b31217;
  }
  
  .social-links:not(.animated-effect-1) li.youtube a:hover,
  .colored.social-links li.youtube a,
  .social-links.animated-effect-1 li.youtube a:after,
  .btn.youtube {
    background-color: #b31217;
    border-color: #b31217;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.flickr a:hover {
    border-color: #ff0084;
  }
  
  .social-links:not(.animated-effect-1) li.flickr a:hover,
  .colored.social-links li.flickr a,
  .social-links.animated-effect-1 li.flickr a:after,
  .btn.flickr {
    background-color: #ff0084;
    border-color: #ff0084;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.facebook a:hover {
    border-color: #3b5998;
  }
  
  .social-links:not(.animated-effect-1) li.facebook a:hover,
  .colored.social-links li.facebook a,
  .social-links.animated-effect-1 li.facebook a:after,
  .btn.facebook {
    background-color: #3b5998;
    border-color: #3b5998;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.pinterest a:hover {
    border-color: #cb2027;
  }
  
  .social-links:not(.animated-effect-1) li.pinterest a:hover,
  .colored.social-links li.pinterest a,
  .social-links.animated-effect-1 li.pinterest a:after,
  .btn.pinterest {
    background-color: #cb2027;
    border-color: #cb2027;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.instagram a:hover {
    border-color: #517fa4;
  }
  
  .social-links:not(.animated-effect-1) li.instagram a:hover,
  .colored.social-links li.instagram a,
  .social-links.animated-effect-1 li.instagram a:after,
  .btn.instagram {
    background-color: #517fa4;
    border-color: #517fa4;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.vimeo a:hover {
    border-color: #aad450;
  }
  
  .social-links:not(.animated-effect-1) li.vimeo a:hover,
  .colored.social-links li.vimeo a,
  .social-links.animated-effect-1 li.vimeo a:after,
  .btn.vimeo {
    background-color: #aad450;
    border-color: #aad450;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.tumblr a:hover {
    border-color: #32506d;
  }
  
  .social-links:not(.animated-effect-1) li.tumblr a:hover,
  .colored.social-links li.tumblr a,
  .social-links.animated-effect-1 li.tumblr a:after,
  .btn.tumblr {
    background-color: #32506d;
    border-color: #32506d;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.soundcloud a:hover {
    border-color: #ff3a00;
  }
  
  .social-links:not(.animated-effect-1) li.soundcloud a:hover,
  .colored.social-links li.soundcloud a,
  .social-links.animated-effect-1 li.soundcloud a:after,
  .btn.soundcloud {
    background-color: #ff3a00;
    border-color: #ff3a00;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.foursquare a:hover {
    border-color: #0072b1;
  }
  
  .social-links:not(.animated-effect-1) li.foursquare a:hover,
  .colored.social-links li.foursquare a,
  .social-links.animated-effect-1 li.foursquare a:after,
  .btn.foursquare {
    background-color: #0072b1;
    border-color: #0072b1;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.dribbble a:hover {
    border-color: #ea4c89;
  }
  
  .social-links:not(.animated-effect-1) li.dribbble a:hover,
  .colored.social-links li.dribbble a,
  .social-links.animated-effect-1 li.dribbble a:after,
  .btn.dribbble {
    background-color: #ea4c89;
    border-color: #ea4c89;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.xing a:hover {
    border-color: #126567;
  }
  
  .social-links:not(.animated-effect-1) li.xing a:hover,
  .colored.social-links li.xing a,
  .social-links.animated-effect-1 li.xing a:after,
  .btn.xing {
    background-color: #126567;
    border-color: #126567;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.behance a:hover {
    border-color: #126567;
  }
  
  .social-links:not(.animated-effect-1) li.behance a:hover,
  .colored.social-links li.behance a,
  .social-links.animated-effect-1 li.behance a:after,
  .btn.behance {
    background-color: #053eff;
    border-color: #053eff;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.vine a:hover {
    border-color: #126567;
  }
  
  .social-links:not(.animated-effect-1) li.vine a:hover,
  .colored.social-links li.vine a,
  .social-links.animated-effect-1 li.vine a:after,
  .btn.vine {
    background-color: #00a478;
    border-color: #00a478;
    color: #ffffff;
  }
  
  .social-links.animated-effect-1 li.stumbleupon a:hover {
    border-color: #126567;
  }
  
  .social-links:not(.animated-effect-1) li.stumbleupon a:hover,
  .colored.social-links li.stumbleupon a,
  .social-links.animated-effect-1 li.stumbleupon a:after,
  .btn.stumbleupon {
    background-color: #f74425;
    border-color: #f74425;
    color: #ffffff;
  }
  
  .social-links .dropdown > button {
    padding: 1px 15px 0px;
    font-size: 16px;
    color: #999999;
    text-align: center;
    min-width: 0;
    margin: 0;
    background-color: transparent;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  
  .social-links .dropdown > button i {
    width: 15px;
  }
  
  .header-top.colored .social-links .dropdown > button > i:before,
  .header-top.colored .social-links .dropdown.show > button > i:before {
    color: #ffffff;
  }
  
  .header-top .social-links .dropdown.show > button > i:before {
    content: "\f00d";
  }
  
  .social-links .dropdown-menu {
    z-index: 103;
    padding: 5px;
    min-width: 270px;
    margin-top: 6px;
    font-size: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #f2f2f2;
    border: 1px solid #D0D0D0;
    border-top: none;
  }
  
  .social-links .dropdown-menu li a,
  .dark .social-links .dropdown-menu li a i,
  .colored .social-links .dropdown-menu li a i {
    padding: 0;
    line-height: 30px;
    color: #ffffff;
  }
  
  .social-links .dropdown-menu li a i {
    padding-left: 0;
  }
  
  .social-links .dropdown-menu li a:hover i {
    color: #ffffff;
  }
  
  /*Images and Overlays
  ---------------------------------- */
  .overlay-container {
    position: relative;
    display: block;
    text-align: center;
    overflow: hidden;
  }
  
  .overlay-bottom,
  .overlay-top {
    position: absolute;
    top: auto;
    padding: 15px;
    bottom: 0px;
    left: 0;
    right: 0px;
    background-color: rgba(30, 30, 30, 0.5);
    overflow: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all ease-in-out 0.25s;
    -o-transition: all ease-in-out 0.25s;
    transition: all ease-in-out 0.25s;
    height: 0;
    color: #ffffff;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
  }
  
  .overlay-bottom.links,
  .overlay-to-top.links {
    z-index: 11;
  }
  
  .overlay-to-top {
    position: absolute;
    top: auto;
    padding: 10px;
    bottom: -30px;
    left: 0;
    right: 0px;
    background-color: rgba(30, 30, 30, 0.5);
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all ease-in-out 0.25s;
    -o-transition: all ease-in-out 0.25s;
    transition: all ease-in-out 0.25s;
    color: #ffffff;
  }
  
  .overlay-top {
    top: 0;
  }
  
  .overlay-visible .overlay-bottom,
  .overlay-visible .overlay-top {
    opacity: 1;
    filter: alpha(opacity=100);
    height: auto !important;
    padding-bottom: 20px;
  }
  
  .overlay-visible.overlay-container:hover .overlay-bottom:not(.links),
  .overlay-visible.overlay-container:hover .overlay-top {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  
  .overlay-top a,
  .overlay-bottom a,
  .overlay-to-top a,
  .overlay-top a:hover,
  .overlay-bottom a:hover,
  .overlay-to-top a:hover {
    color: #ffffff;
    text-decoration: none;
  }
  
  .overlay-container .text {
    padding: 0 20px;
    position: relative;
  }
  
  .overlay-container .text h3,
  .overlay-container .text h3 .small {
    margin-top: 0;
    color: #ffffff;
  }
  
  .overlay-container .text a:hover {
    color: #ffffff;
  }
  
  .overlay-bottom .btn,
  .overlay-top .btn {
    color: #ffffff;
    border-color: #ffffff;
    margin: 0;
  }
  
  .overlay-bottom .btn:hover,
  .overlay-top .btn:hover {
    color: #777777;
    background-color: #ffffff;
  }
  
  .overlay-container:hover .overlay-top,
  .overlay-container:hover .overlay-bottom,
  .image-box.style-2:hover .overlay-top,
  .image-box.style-2:hover .overlay-bottom {
    height: 50%;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
  }
  
  .overlay-container:hover .overlay-to-top,
  .image-box:hover .overlay-to-top {
    opacity: 1;
    filter: alpha(opacity=100);
    bottom: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
  }
  
  .overlay-link {
    position: absolute;
    z-index: 10;
    top: 0;
    padding: 15px;
    bottom: 0px;
    left: 0;
    right: 0px;
    background-color: rgba(30, 30, 30, 0.5);
    overflow: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all ease-in-out 0.25s;
    -o-transition: all ease-in-out 0.25s;
    transition: all ease-in-out 0.25s;
    color: #ffffff;
  }
  
  .overlay-link i {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    width: 80px;
    height: 80px;
    border: 1px solid #ffffff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    font-size: 32px;
    line-height: 80px;
  }
  
  .overlay-link i:hover {
    background-color: #ffffff;
    color: #777777;
  }
  
  .overlay-link.small i {
    width: 25px;
    height: 25px;
    margin: -12.5px 0 0 -12.5px;
    font-size: 14px;
    line-height: 25px;
  }
  
  .overlay-container:hover .overlay-link {
    height: 100%;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
  }
  
  /* Large devices (Large desktops 1200px and up) */
  @media (min-width: 1200px) {
    .overlay-container .text {
      top: 30%;
    }
  }
  
  img {
      display: block;
      max-width: 100%;
      height: auto;
  }
  
  
  /*Buttons with radius*/
  .radius-50 {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
  }
  
  /*Animated Buttons*/
  .btn-animated {
    position: relative;
    min-width: 0 !important;
    padding-right: 45px !important;
  }
  
  .btn-animated i {
    -webkit-transition: right 0.2s ease-in-out;
    -o-transition: right 0.2s ease-in-out;
    transition: right 0.2s ease-in-out;
    z-index: 3;
    right: 17px;
    position: absolute;
    line-height: 34px;
    top: 0;
    min-width: 12px;
  }
  
  .btn-animated:after {
    width: 35px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    content: "";
    top: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    right: 0px;
    z-index: 2;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  
  .btn-animated.radius-50:after {
    border-radius: 0 50px 50px 0;
  }
  
  .btn-animated.btn-gray-transparent:after {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .btn-animated:hover:after {
    opacity: 1;
    filter: alpha(opacity=100);
  }
  
  .btn-animated:hover i {
    right: 11px;
  }
  
  .btn-animated.btn-sm {
    padding-right: 35px !important;
  }
  
  .btn-animated.btn-sm:after {
    width: 25px;
  }
  
  .btn-animated.btn-sm i {
    right: 14px;
    line-height: 28px;
    min-width: 11px;
  }
  
  .btn-animated.btn-sm:hover i {
    right: 7px;
  }
  
  .btn-animated.btn-lg {
    padding-right: 60px !important;
  }
  
  .btn-animated.btn-lg:after {
    width: 45px;
  }
  
  .btn-animated.btn-lg i {
    right: 25px;
    line-height: 48px;
    min-width: 15px;
  }
  
  .btn-animated.btn-lg:hover i {
    right: 15px;
  }
  
  .btn-animated.btn-xl {
    padding-right: 60px !important;
  }
  
  .btn-animated.btn-xl:after {
    width: 45px;
  }
  
  .btn-animated.btn-xl i {
    right: 25px;
    line-height: 64px;
    min-width: 15px;
    text-align: center;
  }
  
  .btn-animated.btn-xl:hover i {
    right: 13px;
  }
  
  /*Background Transition*/
  .btn-animation {
    -webkit-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  
  .btn-animation:hover,
  .btn-animation:focus {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .btn-animation:before {
    background-color: transparent;
    content: "";
    position: absolute;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .btn-animation:hover:before,
  .btn-animation:focus:before {
    z-index: -1;
  }
  
  .btn-default-transparent.btn-animation:hover,
  .btn-default-transparent.btn-animation:focus {
    background-color: transparent;
  }
  
  .btn-animation.btn-animation--slide-to-right:before {
    top: 0;
    left: 0;
    right: 100%;
    bottom: 0;
  }
  
  .btn-animation.btn-animation--slide-to-right:hover:before,
  .btn-animation.btn-animation--slide-to-right:focus:before {
    right: 0;
  }
  
  .btn-animation.btn-animation--slide-to-left:before {
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
  }
  
  .btn-animation.btn-animation--slide-to-left:hover:before,
  .btn-animation.btn-animation--slide-to-left:focus:before {
    left: 0;
  }
  
  .btn-animation.btn-animation--slide-to-top:before {
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .btn-animation.btn-animation--slide-to-top:hover:before,
  .btn-animation.btn-animation--slide-to-top:focus:before {
    top: 0;
  }
  
  .btn-animation.btn-animation--slide-to-bottom:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 100%;
  }
  
  .btn-animation.btn-animation--slide-to-bottom:hover:before,
  .btn-animation.btn-animation--slide-to-bottom:focus:before {
    bottom: 0;
  }
  
  .btn-animation.btn-animation--slide-horizontal:before {
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
  }
  
  .btn-animation.btn-animation--slide-horizontal:hover:before,
  .btn-animation.btn-animation--slide-horizontal:focus:before {
    left: 0;
    right: 0;
  }
  
  .btn-animation.btn-animation--slide-vertical:before {
    top: 50%;
    left: 0;
    right: 0;
    bottom: 50%;
  }
  
  .btn-animation.btn-animation--slide-vertical:hover:before,
  .btn-animation.btn-animation--slide-vertical:focus:before {
    top: 0;
    bottom: 0;
  }