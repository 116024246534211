
  /* .full-size-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 150px; 
    border-radius: 20px;
  }
  .full-size-image img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
    
  }
  .full-size-image img:hover {
    transform: scale(1.1);
  }
  .image-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 20px;
  } */

  .full-size-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 150px;
    border-radius: 20px;
  }
  
  .full-size-image img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
  }
  
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    opacity: 0.7; /* Adjust the default opacity */
    transition: opacity 0.3s ease; /* Smooth transition */
  }
  
  .full-size-image:hover .image-overlay {
    opacity: 0; /* Make the overlay invisible on hover */
  }
  
  .image-overlay:hover {
    opacity: 0.7; /* Make the overlay visible on hover */
  }
  
  .image-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 18px;
    width:90%;
    font-weight: 700;
  }
  