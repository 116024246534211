
/* .wraperr-blog-card img{
    z-index: 1;
    position: relative;
    width: 100%
   
 }

 .wraperr-blog-card {
    position: relative;
    overflow: hidden;
    
 }

 .wraperr-blog-card::before {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}

.wraperr-blog-card:hover::before {
	-webkit-animation: shine 1s;
	animation: shine 1s;
}

@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}



 .wraperr-blog-card:hover .blog-bottom-container{
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
  
 }


 .wraperr-blog-card figure img{
    
    -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
   

 }

 .wraperr-blog-card:hover img {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
   
}


  .blog-bottom-container{
    position: relative;
    margin-top: -65px;
    margin-left: 30px;
    transition: all 300ms ease;
    padding: 5px 0px 20px 0px;
    background-color: #fff;
    border-radius: 10px 0 10px 10px;
    z-index: 2;
    
}
.blog-bottom-container ul{
    list-style: none;
   
    padding: 30px 0px 0px 20px;
    gap: 2px;
}
@media only screen and (min-width: 1100px) {
    ul {
      display: flex;
    }
  }
.blog-bottom-container ul li{
    margin: 10px;
    font-size: smaller;
    margin-top: .8rem;
    
}

.blog-bottom-container h3{
    padding-left: 20px;
    padding-top: .5rem;
    font-size: larger;
    font-weight: bold;
    
}
.blog-bottom-container h3 a:link{
    color: black;
}
.blog-bottom-container h3 a:hover{
    color: #115cc9;
}
.blog-bottom-container h4{
    padding-left: 20px;
    padding-top: .5rem;
    font-size: larger;
    font-weight: bold;
}

.blog-bottom-container a{
    padding-top: .5rem;
    text-decoration: none;
    font-weight: 600;
   
}
.blog-bottom-container h6{
    margin-top: 1rem;
}


.blog-bottom-container h6 a:link{
    color: black;
    padding-left: 20px;
}


.blog-bottom-container h6 a span{
    padding: 1rem;
    
}



.blog-bottom-container ul li a:link{
    text-decoration: none !important;
}
.calnder{
    padding:.4rem 1rem .4rem 1rem;
    background-image: linear-gradient(45deg, #00bf67, #115cc9) !important;
    text-decoration: none;
    border-radius: 15px;
    color: #fff;
    

   
}
.contec-div ul li .calnder:link{
    color: #fff;
}  */


.blog-grid {
    border-radius: 5px;
    overflow: hidden;
    background: #ffffff;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .blog-grid .blog-img {
    position: relative;
    overflow: hidden;
  }
  .blog-grid .blog-img .date {
    position: absolute;
    background: #d4a54e;
    color: #ffffff;
    padding: 8px 15px;
    left: 10px;
    top: 10px;
    border-radius: 4px;
    z-index: 1;
  }
  .blog-grid .blog-img .date span {
    font-size: 22px;
    display: block;
    line-height: 22px;
    font-weight: 700;
  }
  .blog-grid .blog-img .date label {
    font-size: 14px;
    margin: 0;
  }
  .blog-grid .blog-info {
    padding: 20px;
    padding-top: 14px;
  }
  .blog-grid .blog-info h5 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px;
    line-height: 25px;
  }
  .blog-grid .blog-info h5 a {
    color: #000;
  }
  .blog-grid .blog-info p {
    margin: 0;
  }
  .blog-grid .blog-info .btn-bar {
    margin-top: 20px;
  }
  
  
  /* Blog Sidebar
  -------------------*/
  .blog-aside .widget {
    box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
    border-radius: 5px;
    overflow: hidden;
    background: #ffffff;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
  .blog-aside .widget-body {
    padding: 15px;
  }
  .blog-aside .widget-title {
    padding: 15px;
    border-bottom: 1px solid #eee;
  }
  .blog-aside .widget-title h3 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    margin: 0;
  }
  .blog-aside .widget-author .media {
    margin-bottom: 15px;
  }
  .blog-aside .widget-author p {
    font-size: 16px;
    margin: 0;
  }
  .blog-aside .widget-author .avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
  }
  .blog-aside .widget-author h6 {
    font-weight: 600;
    color: #20247b;
    font-size: 22px;
    margin: 0;
    padding-left: 20px;
  }
  .blog-aside .post-aside {
    margin-bottom: 15px;
  }
  .blog-aside .post-aside .post-aside-title h5 {
    margin: 0;
  }
  .blog-aside .post-aside .post-aside-title a {
    font-size: 18px;
    color: #20247b;
    font-weight: 600;
  }
  .blog-aside .post-aside .post-aside-meta {
    padding-bottom: 10px;
  }
  .blog-aside .post-aside .post-aside-meta a {
    color: #6F8BA4;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    margin-right: 10px;
  }
  .blog-aside .latest-post-aside + .latest-post-aside {
    border-top: 1px solid #eee;
    padding-top: 15px;
    margin-top: 15px;
  }
  .blog-aside .latest-post-aside .lpa-right {
    width: 90px;
  }
  .blog-aside .latest-post-aside .lpa-right img {
    border-radius: 3px;
  }
  .blog-aside .latest-post-aside .lpa-left {
    padding-right: 15px;
  }
  .blog-aside .latest-post-aside .lpa-title h5 {
    margin: 0;
    font-size: 15px;
  }
  .blog-aside .latest-post-aside .lpa-title a {
    color: #20247b;
    font-weight: 600;
  }
  /* .lpa-title{
    margin-left: -5px;
  } */
  .blog-aside .latest-post-aside .lpa-meta a {
    color: #6F8BA4;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    margin-right: 10px;
  }
  
  .tag-cloud a {
    padding: 4px 15px;
    font-size: 13px;
    color: #ffffff;
    background: #d4a54e;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-weight: 700;
  }
  .tag-cloud a:hover {
    background: #fc5356;
  }
  
  .blog-single {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .article {
    /* box-shadow: 0 0 30px rgba(31, 45, 61, 0.125); */
    border-radius: 5px;
    overflow: hidden;
    /* background: #ffffff; */
    padding: 15px;
    margin: 15px 0 30px;
  }
  .article .article-title {
    padding: 15px 0 20px;
  }
  .article .article-title h6 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .article .article-title h6 a {
    text-transform: uppercase;
    color: #fc5356;
    border-bottom: 1px solid #fc5356;
  }
  .article .article-title h2 {
    color: #20247b;
    font-weight: 600;
  }
  .article .article-title .media {
    padding-top: 15px;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 20px;
  }
  .article .article-title .media .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
  }
  .article .article-title .media .media-body {
    padding-left: 8px;
  }
  .article .article-title .media .media-body label {
    font-weight: 600;
    color: #fc5356;
    margin: 0;
  }
  .article .article-title .media .media-body span {
    display: block;
    font-size: 12px;
  }
  .article .article-content h1,
  .article .article-content h2,
  .article .article-content h3,
  .article .article-content h4,
  .article .article-content h5,
  .article .article-content h6 {
    color: #20247b;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .article .article-content blockquote {
    max-width: 600px;
    padding: 15px 0 30px 0;
    margin: 0;
  }
  .article .article-content blockquote p {
    font-size: 20px;
    font-weight: 500;
    color: #fc5356;
    margin: 0;
  }
  .article .article-content blockquote .blockquote-footer {
    color: #20247b;
    font-size: 16px;
  }
  .article .article-content blockquote .blockquote-footer cite {
    font-weight: 600;
  }
  .article .tag-cloud {
    padding-top: 10px;
  }
  
  .article-comment {
    box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
    border-radius: 5px;
    overflow: hidden;
    background: #ffffff;
    padding: 20px;
  }
  .article-comment h4 {
    color: #20247b;
    font-weight: 700;
    margin-bottom: 25px;
    font-size: 22px;
  }
  .blg-cate-date {
      display: flex;
      padding-left: 20px;
      padding-top: 15px;
      padding-right: 20px;
  }
  .blg-date span {
      background: #d4a54e;
      color: #fff;
      padding: 5px 14px;
      border-radius: 50px;
      font-size: 12px;
      font-weight: 700;
  }
  .blg-date {
      margin-right: 10px;
  }
  .blg-cate span {
      background: #d4a54e;
      color: #fff;
      padding: 5px 14px;
      border-radius: 50px;
      font-size: 12px;
      font-weight: 700;
  }
  .blog-grid .blog-info .btn-bar a.px-btn-arrow {
      color: #000;
      font-weight: 900;
      font-size: 14px;
  }
  .blg-btm {
      margin-top: -24px;
      z-index: 999999;
      position: relative;
      background: #fff;
      margin-left: 12px;
      border-radius: 5px 0px 0px 5px;
      box-shadow: 0px 3px 24px rgba(31, 45, 61, 0.125);
      margin-bottom: 20px;
  }
  .blog-img img {
      height: 210px;
      width: 100%;
      object-fit: cover;
      transition: .5s;
  }
  .blog-grid:hover .blog-img img {
      scale: 1.1;
      transition: .5s;
  }
  .blog-grid .blog-info .btn-bar a.px-btn-arrow:hover {
      color: #d4a54e;
  }
  section.blog-listing .page-item.active .page-link {
      background-color: #d4a54e;
      border-color: #d4a54e;
  }
  section.blog-listing .page-link {
      color: #000000;
  }
  .category-body ul {
      list-style: none;
      padding: 0;
      padding-left: 16px;
      padding-top: 12px;
  }
  .category-body ul li a {
      color: #000;
      font-weight: 700;
      font-size: 14px;
  }
  .category-body ul li a:hover {
      color: #d4a54e;
  }
  .widget-body .lpa-title h5 a {
      color: #000 !important;
  }
  .widget-body .lpa-title h5 a:hover {
      color: #d4a54e !important;
  }
  section.blog-listing {
      padding-top: 30px;
  }
  .lpa-right img {
    width: 100%;
    height: 60px;
    /* height: 'auto'; */
    display: block;
    transition: transform 0.3s ease;
  }
  .lpa-right img:hover {
    transform: scale(1.1);
  }

  /* .full-size-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100px;
    border-radius: 20px;
  }
   */
  .letest_box_img_box img {
    /* width: 100%;
    height: 100px;
    display: block; */
    transition: transform 0.3s ease;
  }
  
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    opacity: 0.7; /* Adjust the default opacity */
    transition: opacity 0.3s ease; /* Smooth transition */
    border-radius: 10px;
  }
  
  .letest_box_img_box:hover .image-overlay {
    opacity: 0; /* Make the overlay invisible on hover */
  }
  
  .image-overlay:hover {
    opacity: 0.7; /* Make the overlay visible on hover */
  }
  .letest_blog_text{
    font-weight: 500;
    color:#000000
  }
  .letest_blog_text:hover{
    color: #20247b;
  }
/* 
 .blog-color-primary{
      color: #02598A;
 }

 .blog-main-heading {
  font-size: 42px;
  font-weight: bold;
  line-height: 1.2;
}


 .contributor-card-img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
 }
 .contributor-card-value{
    font-weight: bold;
 }
 .breadcrumb{
  margin-left: -10px;
 }
 ol li {
  color: rgb(100, 99, 99);
 }

 ol li a{
  cursor: pointer;
  color: rgb(100, 99, 99);
}
ol li:hover {
  color: #0e94ac;
}
 ol li a:hover {
  color: #0e94ac;
}

 @media (max-width: 768px) {
  .blog-main-heading {
    font-size: 36px; 
  }
}

@media (max-width: 576px) {
  .blog-main-heading {
    font-size: 20px; 
  }
} */