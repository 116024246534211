
/* The Modal (background) */
.modal123 {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content123 {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  /* .showmobaile {
    display: none;
  } */

  @media screen and (max-width: 990px) {
    .hidemore{
      display: none;
      /* background-color: lightgreen; */
    }
  }
  @media screen and (min-width: 990px) {
    .showmobaile{
      display: none;
      /* background-color: lightgreen; */
    }
    
      /* background-color: lightgreen; */
    }
  